import { Controller as BaseController } from "@hotwired/stimulus"
import { createConsumer } from "@rails/actioncable"

class Timer {
  constructor() {
    this.startTime = 0
    this.elapsedTime = 0
    this.timerInterval = null
  }

  start() {
    if (!this.timerInterval) {
      this.startTime = Date.now() - this.elapsedTime
      this.timerInterval = setInterval(() => {
        this.elapsedTime = Date.now() - this.startTime
        this.update()
      }, 1000)
    }
  }

  stop() {
    clearInterval(this.timerInterval)
    this.timerInterval = null
  }

  update() {
    const startTime = parseInt(document.querySelector('#transcriber-timer').dataset.startTime)
    const diffInSeconds = (Math.floor(this.elapsedTime / 1000) + startTime)
    const hours = Math.floor(diffInSeconds / 3600)
    const minutes = Math.floor((diffInSeconds % 3600) / 60)
    const seconds = diffInSeconds % 60

    const displayHours = String(hours).padStart(2, '0')
    const displayMinutes = String(minutes).padStart(2, '0')
    const displaySeconds = String(seconds).padStart(2, '0')

    document.querySelector('#transcriber-timer').textContent = 
      `${displayHours}:${displayMinutes}:${displaySeconds}`
  }
}

export class Controller extends BaseController {
  static targets = [
    "startButton",
    "stopButton",
    "finalizeButton",
    "footerButton",
    "deleteButton",
    "micFeedback",
    "micInfo",
    "timer",
    "audioFilesIndex",
    "oracleIndex"
  ]

  static values = {
    transcriptionSgid: String,
    transcriptionId: Number,
    autoStart: { type: Boolean, default: true }
  }

  connect() {
    // Initialize recording-related variables
    this.mediaRecorder = null
    this.stream = null
    this.chunks = []
    this.timer = new Timer()
    this.startTime = 0
    this.stopTime = 0
    this.lastGreenUpdateTime = 0
    this.recorderInterval = null
    window.transcriberTimer = this.timer

    // Audio constraints for optimal recording
    this.audioConstraints = {
      echoCancellation: true,
      noiseSuppression: true,
      autoGainControl: true,
      sampleRate: 16000,
      channelCount: 1,
      latency: 0
    }

    // Initialize form-specific features
    this.setupFieldAutoSubmit()
    this.setupActionCableSubscription()

    // Add a small delay before auto-starting recording
    if (this.autoStartValue) {
      // Use RAF to ensure DOM is ready
      requestAnimationFrame(() => {
        // Add a small delay to ensure browser is ready
        setTimeout(() => {
          this.startRecording()
        }, 1000)
      })
    }
  }

  disconnect() {
    this.stopRecording()
    if (this.timer) {
      this.timer.stop()
    }
    if (this.subscription) {
      createConsumer().subscriptions.remove(this.subscription)
    }
  }

  // Form-specific methods
  setupFieldAutoSubmit() {
    this.element.querySelectorAll('.field-submit-form-on-change').forEach(field => {
      field.addEventListener('change', () => {
        this.submitForm()
      })
    })
  }

  submitForm() {
    // Get the Rails form and submit it
    this.element.closest('form').requestSubmit()
  }

  // Recording methods
  async startRecording() {
    try {
      window.onbeforeunload = () => {
        return 'There is an Ava Scribe in progress - if you leave it will be lost. Are you sure you want to leave?'
      }

      this.timer.start()
      this.startButtonTarget.classList.add('hidden-field')
      if (this.hasFooterButtonTarget) {
        this.footerButtonTarget.classList.add('instant-messenger-unread')
      }
      this.stopButtonTarget.classList.remove('hidden-field')

      if (window.scribeRecording) return

      this.stream = await navigator.mediaDevices.getUserMedia({ audio: this.audioConstraints })
      this.mediaRecorder = new MediaRecorder(this.stream, {
        mimeType: 'audio/webm; codecs=opus',
        audioBitsPerSecond: 256000,
      })

      const audioContext = new (window.AudioContext || window.webkitAudioContext)()
      const source = audioContext.createMediaStreamSource(this.stream)
      const analyser = audioContext.createAnalyser()
      
      analyser.smoothingTimeConstant = 0.9
      analyser.fftSize = 512
      const bufferLength = analyser.frequencyBinCount
      const dataArray = new Uint8Array(bufferLength)

      const analyzeMicLevel = () => {
        analyser.getByteFrequencyData(dataArray)
        const sum = dataArray.reduce((a, b) => a + b, 0)
        const average = sum / dataArray.length
        const now = Date.now()

        if (this.hasMicFeedbackTarget) {
          if (average > 55) {
            this.micFeedbackTarget.style.color = 'green'
            this.lastGreenUpdateTime = now
          } else if (average > 30) {
            if (now - this.lastGreenUpdateTime > 1500) {
              this.micFeedbackTarget.style.color = 'orange'
            }
          } else {
            if (now - this.lastGreenUpdateTime > 1500) {
              this.micFeedbackTarget.style.color = 'red'
            }
          }
        }

        requestAnimationFrame(analyzeMicLevel)
      }

      analyzeMicLevel()
      source.connect(analyser)

      if (this.hasMicInfoTarget) {
        const audioTracks = this.stream.getAudioTracks()
        if (audioTracks.length > 0) {
          this.micInfoTarget.textContent = audioTracks[0].label
        }
      }

      this.mediaRecorder.addEventListener('dataavailable', (e) => {
        if (e.data.size > 0) {
          this.chunks.push(e.data)
        }
      })

      this.mediaRecorder.addEventListener('stop', () => {
        this.stopTime = Date.now()
        const blob = new Blob(this.chunks, { type: 'audio/webm' })
        this.saveAudio(blob)
        this.chunks = []
      })

      this.mediaRecorder.addEventListener('start', () => {
        this.startTime = Date.now()
        this.chunks = []
      })

      this.mediaRecorder.start()

      if (!this.recorderInterval) {
        this.recorderInterval = setInterval(() => {
          if (this.mediaRecorder?.state === 'recording') {
            this.mediaRecorder.stop()
            this.mediaRecorder.start()
          }
        }, 0.5 * 60 * 1000) // 30 seconds
      }

      window.scribeRecording = true

    } catch (err) {
      console.error('Error while starting recording:', err)
      alert('Unable to access the microphone. Please check your microphone settings and permissions.')
      this.startButtonTarget.classList.remove('hidden-field')
      if (this.hasFooterButtonTarget) {
        this.footerButtonTarget.classList.remove('instant-messenger-unread')
      }
      this.stopButtonTarget.classList.add('hidden-field')
      window.onbeforeunload = null
      this.timer.stop()
    }
  }

  stopRecording() {
    if (this.mediaRecorder?.state === 'recording') {
      this.mediaRecorder.stop()
      this.startButtonTarget.classList.remove('hidden-field')
      if (this.hasFooterButtonTarget) {
        this.footerButtonTarget.classList.remove('instant-messenger-unread')
      }
      this.stopButtonTarget.classList.add('hidden-field')
      window.onbeforeunload = null
      this.timer.stop()
      this.stream.getTracks().forEach(track => track.stop())
      window.scribeRecording = null

      if (this.recorderInterval) {
        clearInterval(this.recorderInterval)
        this.recorderInterval = null
      }
    }
  }

  async saveAudio(blob) {
    const formData = new FormData()
    const duration = Math.round((this.stopTime - this.startTime) / 100) / 10
    formData.append('audio_file', blob)
    formData.append('transcription_sgid', this.transcriptionSgidValue)
    formData.append('duration', duration)
    formData.append('order', Date.now())
    
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content

    try {
      const response = await fetch('/transcriber/transcription_audio_files', {
        method: 'POST',
        body: formData,
        headers: {
          'X-CSRF-Token': csrfToken,
        },
        credentials: 'same-origin',
      })

      if (!response.ok) {
        throw new Error(`HTTP error: ${response.status}`)
      }

      const data = await response.json()
      this.addTranscriptionAudioFileElement(data, duration)

    } catch (err) {
      window.onbeforeunload = null
      console.error('Error saving audio:', err)
    }
  }

  async deleteTranscription(event) {
    if (!confirm("If deleted, you cannot recover this Scribe. Are you sure you want to delete this Scribe?")) {
      return
    }

    this.stopRecording()
    window.scribeRecording = null

    const url = event.currentTarget.dataset.url
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content

    try {
      await fetch(url, {
        method: 'DELETE',
        headers: {
          'X-CSRF-Token': csrfToken,
          'Accept': 'application/javascript'
        }
      })
      
      this.timer.stop()
      window.scribeRecording = null
    } catch (err) {
      console.error('Error deleting transcription:', err)
    }
  }

  async finalizeTranscription(event) {
    const url = event.currentTarget.dataset.url
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content
    
    event.currentTarget.disabled = true
    event.currentTarget.classList.add('disabled')

    if (window.scribeRecording) {
      this.stopRecording()
      setTimeout(() => {
        this.patchFinalizeTranscription(url, csrfToken)
      }, 1000)
    } else {
      this.patchFinalizeTranscription(url, csrfToken)
    }
  }

  async patchFinalizeTranscription(url, csrfToken) {
    try {
      const response = await fetch(url, {
        method: 'PATCH',
        headers: {
          'X-CSRF-Token': csrfToken,
          'Accept': 'text/javascript',
          'Content-Type': 'application/javascript'
        },
        credentials: 'same-origin'
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      // Get the JavaScript content from the response
      const jsContent = await response.text()
      
      // Execute the JavaScript response
      const script = document.createElement('script')
      script.textContent = jsContent
      document.body.appendChild(script)
      script.remove()
    } catch (err) {
      console.error('Error finalizing transcription:', err)
    }
  }

  // Transcription Audio File Stuff
  addTranscriptionAudioFileElement(data) {
    const newSpan = document.createElement('span')
    newSpan.classList.add('transcriber-audio-file')
    newSpan.setAttribute('data-transcription-audio-file-id', data.id)
    document.querySelector('#transcription-audio-files-index').appendChild(newSpan)
  }

  setupActionCableSubscription() {
    if (!this.transcriptionIdValue) return

    const subscriptionObject = {
      channel: 'EmrChannels::TranscriptionChannel',
      id: this.transcriptionIdValue
    }

    this.subscription = createConsumer().subscriptions.create(
      subscriptionObject,
      {
        initialized: () => {},
        
        connected: () => {
          console.log('Connected to transcription channel')
        },
        
        received: (data) => {
          switch(data.messageType) {
            case 'transcription_audio_file_status_update':
              this.handleAudioFileStatusUpdate(data.id, data.status, data.message)
              break
            case 'transcription_data_update':
              this.handleTranscriptionDataUpdate(data.message)
              this.handleTranscriptionStatusUpdate(data.status)
              break
            case 'transcription_audio_file_oracle':
              this.handleTranscriptionOracle(data.id, data.medical_concepts)
              break
          }
        }
      }
    )
  }

  handleAudioFileStatusUpdate(id, status, message) {
    const audioFileElement = this.audioFilesIndexTarget.querySelector(
      `[data-transcription-audio-file-id="${id}"]`
    )
    if (!audioFileElement) return

    // Remove all status classes
    audioFileElement.classList.remove(
      'status-completed',
      'status-error',
      'status-in-progress',
      'status-pending'
    )

    // Add appropriate status class
    audioFileElement.classList.add(`status-${status.toLowerCase().replace(' ', '-')}`)
  }

  handleTranscriptionOracle(id, medicalConcepts) {
    if (!this.hasOracleIndexTarget) return

    const medicalConceptsDiv = document.createElement('div')
    medicalConceptsDiv.classList.add('ava-flex', 'medical_concepts', 'text-overflow-ellipsis')

    const conceptElements = medicalConcepts.slice(0, 4).map(concept => {
      const span = document.createElement('span')
      span.classList.add('ava-col-3')

      const link = document.createElement('a')
      link.href = `/patient_view/patients/${concept.patient_id}/medical_concepts/${concept.id}?transcription_audio_file_id=${id}`
      link.textContent = concept.name
      link.classList.add('button', 'text-overflow-ellipsis', 'grey-border', 'wide-button')
      link.dataset.remote = 'true'
      link.title = concept.name

      span.appendChild(link)
      return span
    })

    conceptElements.forEach(element => medicalConceptsDiv.appendChild(element))
    this.oracleIndexTarget.prepend(medicalConceptsDiv)

    // Remove oldest concepts if more than 2 exist
    const allConceptDivs = this.oracleIndexTarget.querySelectorAll('.medical_concepts')
    if (allConceptDivs.length > 2) {
      allConceptDivs[allConceptDivs.length - 1].remove()
    }
  }

}
